var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "flex mb-10 items-center" }, [
      _c("div", { staticClass: "flex items-center justify-center w-full" }, [
        _vm._v(" " + _vm._s(_vm.description) + " "),
      ]),
    ]),
    _vm.showTeachers
      ? _c(
          "div",
          { staticClass: "mb-5" },
          [
            _c("h5", { staticClass: "mb-3" }, [
              _vm._v(" " + _vm._s(_vm.$t("professores")) + " "),
            ]),
            _c(
              "vs-table",
              {
                attrs: { data: _vm.teachers },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ data }) {
                        return _vm._l(data, function (tr, i) {
                          return _c(
                            "vs-tr",
                            { key: data[i], style: _vm.rowStyle(data[i]) },
                            [
                              _c("vs-td", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      data[i].who
                                        ? "(" + _vm.$t("Me") + ") "
                                        : null
                                    ) +
                                    " " +
                                    _vm._s(data[i].name) +
                                    " "
                                ),
                              ]),
                              _c("vs-td", [
                                _vm._v(" " + _vm._s(data[i].email) + " "),
                              ]),
                              _c("vs-td", [
                                _vm._v(
                                  " " + _vm._s(data[i].discipline_title) + " "
                                ),
                              ]),
                            ],
                            1
                          )
                        })
                      },
                    },
                  ],
                  null,
                  false,
                  3500444080
                ),
              },
              [
                _c(
                  "template",
                  { slot: "thead" },
                  [
                    _c("vs-th", { attrs: { width: "30%" } }, [
                      _vm._v(_vm._s(_vm.$t("name"))),
                    ]),
                    _c("vs-th", { attrs: { width: "40%" } }, [
                      _vm._v(_vm._s(_vm.$t("email"))),
                    ]),
                    _c("vs-th", { attrs: { width: "30%" } }, [
                      _vm._v(_vm._s(_vm.$t("discipline"))),
                    ]),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        )
      : _vm._e(),
    _vm.showStudents
      ? _c(
          "div",
          { staticClass: "mb-5" },
          [
            _c("h5", { staticClass: "mb-3" }, [
              _vm._v(_vm._s(_vm.$t("students"))),
            ]),
            _c(
              "vs-table",
              {
                attrs: { data: _vm.students },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ data }) {
                        return _vm._l(data, function (tr, i) {
                          return _c(
                            "vs-tr",
                            { key: data[i], style: _vm.rowStyle(data[i]) },
                            [
                              _c("vs-td", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      data[i].who
                                        ? "(" + _vm.$t("Me") + ") "
                                        : null
                                    ) +
                                    " " +
                                    _vm._s(data[i].name) +
                                    " "
                                ),
                              ]),
                              _c("vs-td", [
                                _vm._v(" " + _vm._s(data[i].email) + " "),
                              ]),
                            ],
                            1
                          )
                        })
                      },
                    },
                  ],
                  null,
                  false,
                  1187994034
                ),
              },
              [
                _c(
                  "template",
                  { slot: "thead" },
                  [
                    _c("vs-th", { attrs: { width: "30%" } }, [
                      _vm._v(_vm._s(_vm.$t("name"))),
                    ]),
                    _c("vs-th", { attrs: { width: "70%" } }, [
                      _vm._v(_vm._s(_vm.$t("email"))),
                    ]),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }